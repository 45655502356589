<template>
  <div style="min-height: 480px;width: 400px;text-align: center;margin-left: 80px;padding-bottom:80px">
    <div class="dialog-title">{{ buttonText }}角色</div>
    <el-form label-width="100px">
      <el-form-item label="角色名称">
        <el-input v-model="dataForm.roleName "></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="dataForm.remark "></el-input>
      </el-form-item>

      <el-form-item size="mini" label="权限">
        <el-tree
            :data="roleList"
            :props="menuListTreeProps"
            node-key="menuId"
            ref="menuListTree"
            :default-checked-keys="menuIdList"
            :default-expand-all="true"
            show-checkbox>
        </el-tree>
      </el-form-item>

    </el-form>
    <el-col>
      <el-button class="dialog-button" @click="update">提交</el-button>
    </el-col>
  </div>
</template>

<script>
// import {treeDataTranslate} from "@/util";
export default {
  inject: [
    'reload'
  ],
  name: "addrole",
  props: {
    roleList: Array,
    changeList: {
      type: Object,
      defalut: []
    }
  },
  data() {
    return {
      id: '',
      menuList: [],
      menuIdList: [],
      tempKey: '-66666',
      buttonText: '添加',
      menuListTreeProps: {
        label: 'name',
        children: 'menuEntityList'
      },
      dataForm: {
        roleName: '',
        remark: ''
      },
    }
  },
  mounted() {
    console.log(this.roleList)
    const _self = this
    if (_self.changeList.roleId) {
      this.buttonText= '修改'
      _self.dataForm.remark = this.changeList.remark
      _self.dataForm.roleName = this.changeList.roleName
      console.log(this.changeList, 'this.changeList', this.changeList.menuIdList)
      _self.menuIdList = this.changeList.menuIdList
      _self.id = this.changeList.roleId
      var idx = _self.changeList.menuIdList.indexOf(this.tempKey)
      if (idx !== -1) {
        // eslint-disable-next-line vue/no-mutating-props
        _self.changeList.menuIdList.splice(idx, _self.changeList.menuIdList.length - idx)
      }
      this.$refs.menuListTree.setCheckedKeys(_self.changeList.menuIdList)
    }
    this.buttonText= '添加'
  },
  methods: {
    // 添加或修改
    update() {
      if (this.id !== '') {  //修改
        this.dataForm['roleId'] = this.id
        // this.dataForm['menuIdList'] = [].concat(this.$refs.menuListTree.getCheckedKeys(), [this.tempKey], this.$refs.menuListTree.getHalfCheckedKeys())
        this.dataForm['menuIdList'] = [].concat(this.$refs.menuListTree.getCheckedKeys(), [this.tempKey], this.$refs.menuListTree.getHalfCheckedKeys())
        this.$api.post('role/update', this.dataForm, res => {
          console.log(res, 'wwww')
          if (res.code === 0) {
            console.log('修改')
            this.reload()
          }
        })
      } else {   //添加
        this.dataForm['menuIdList'] = [].concat(this.$refs.menuListTree.getCheckedKeys(), [this.tempKey], this.$refs.menuListTree.getHalfCheckedKeys())
        this.$api.post('role/save', this.dataForm, res => {
          if (res.code === 0) {
            console.log('添加成功')
            this.reload()
          }
        })
      }

    }
  },
  watch: {
    changeList: {
      handler: function (val) {
        console.log(val, '查看点击修改添加得数据',val.roleId)
        if ( val.roleId !== undefined) {
          console.log('修改')
          this.dataForm.roleName = val.roleName
          this.dataForm.remark = val.remark
          this.menuIdList = val.menuIdList
          this.id = val.roleId
          var idx = val.menuIdList.indexOf(this.tempKey)
          if (idx !== -1) {
            // eslint-disable-next-line vue/no-mutating-props
            val.menuIdList.splice(idx, val.menuIdList.length - idx)
          }
          this.$refs.menuListTree.setCheckedKeys(val.menuIdList)
          this.buttonText = '修改'
        } else {
          console.log('添加')
          this.id = ''
          this.dataForm.roleName = ''
          this.dataForm.remark = ''
          this.menuIdList = []
          this.buttonText = '添加'
          this.$refs.menuListTree.setCheckedKeys([])
        }
      }
    }
  }
}
</script>

<style scoped>
.table-title-name {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0 5px 36px;

}

.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
</style>
