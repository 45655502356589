<template>
  <div>
    <div class="body-box">
      <div style="width: 200px;float:left;">
        <el-menu
            :default-active="selectName"
            class="el-menu-vertical-demo"
            style="text-align: center"
            @select="handleOpen">
          <el-menu-item index="1" v-if="this.$getPermission('/pc/role/getList')">
            <span slot="title">角色权限管理</span>
          </el-menu-item>
          <el-menu-item index="2" v-if="this.$getPermission('/pc/user/getList')">
            <span slot="title">用户管理</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="agricul_right ib">
        <div v-if="selectName == 1" >
          <role_jurisdiction></role_jurisdiction>
        </div>
        <div v-if="selectName == 2">
          <user_admin></user_admin>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import role_jurisdiction from '@/components/personnel/role_jurisdiction'
import user_admin from '@/components/personnel/user_admin'

export default {
  components: {
    role_jurisdiction,
    user_admin,
  },
  data() {
    return {
      selectName: localStorage.getItem('selectIndex'),
    }
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      this.selectName = key
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
  }
}
</script>

<style scoped>
.body-box {
  margin-top: 20px;
}

.ib {
  /*display: inline-block;*/
}

.agricul_right {
  margin-left: 10px;
  width: 990px;

  background: #FFFFFF;
  float: left;
}

.clear {
  clear: both
}

.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}
</style>
