<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">用户管理</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click="hideDialog"
                 v-if="this.$getPermission('/pc/user/save')">添加用户
      </el-button>
      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="600px"
      >
        <div style="height: 590px;width: 400px;text-align: center;margin-left: 80px">
          <div class="dialog-title">用户{{ titleType }}</div>
          <el-form label-width="100px" :rules="dataRule" ref="dataForm">
            <el-form-item label="头像"  >
              <el-upload
                  style="border: 1px solid #cccccc"
                  class="avatar-uploader"
                  :action="url"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>

            </el-form-item>
            <el-form-item label="姓名" >
              <el-input v-model="dataForm.username"></el-input>
            </el-form-item>
            <el-form-item label="手机号" >
              <el-input v-model="dataForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label="密码" v-if="password_show" >
              <el-input v-model="dataForm.password"></el-input>
            </el-form-item>
            <el-form-item label="角色" >
              <el-select v-model="dataForm.roleIdList" multiple placeholder="请选择" style="width: 300px;">
                <el-option
                    v-for="item in roleList"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId"
                >
                </el-option>
              </el-select>
            </el-form-item>


          </el-form>
          <el-col>
            <el-button class="dialog-button" @click="update('dataForm')">提交</el-button>
          </el-col>
        </div>
      </el-dialog>
      <!--      修改密码-->
      <el-dialog
          title=""
          :visible.sync="dialogVisible1"
          width="600px"
      >
        <div style="height: 380px;width: 400px;text-align: center;margin-left: 80px">
          <div class="dialog-title">修改密码</div>
          <el-form label-width="120px">
            <el-form-item label="原密码">
              <el-input v-model="PasswordForm.oldPassword"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input v-model="PasswordForm.newPassword"></el-input>
            </el-form-item>
            <el-form-item label="再次输入新密码">
              <el-input v-model="PasswordForm.new2Password"></el-input>
            </el-form-item>
          </el-form>
          <el-col>
            <el-button class="dialog-button" @click="updatePassword">提交</el-button>
          </el-col>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="userId"
            label="编号"
        >
        </el-table-column>
        <el-table-column
            prop="username"
            label="姓名"
        >
        </el-table-column>
        <el-table-column
            prop="mobile"
            label="头像">
          <template slot-scope="scope">
            <el-popover placement="top-start" title="" trigger="hover">
              <img :src="scope.row.img" alt="" style="width: 150px;height: 150px">
              <img slot="reference" :src="scope.row.img" style="width: 30px;height: 30px">
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
            prop="mobile"
            label="手机号">
        </el-table-column>
        <el-table-column
            prop="roleNames"
            label="角色">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-button
                size="mini"
                type="text"
                @click="changePassword(scope.$index, scope.row)">修改密码
            </el-button>
            <el-popconfirm style="margin-left: 10px"
                           title="确定删除吗？"
                           @confirm="handleDelete(scope.$index, scope.row)"
            >
              <el-button
                  size="mini"
                  type="text"
                  slot="reference"
              >删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-bottom: 20px;margin-left: 30px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  inject: [
    'reload'
  ],
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      imageUrl: '',
      titleType: '添加',
      password_show: true,
      dataForm: {
        mobile: '',
        password: '',
        roleIdList: '',
        username: '',
      },
      PasswordForm: {
        oldPassword: '',
        newPassword: '',
        new2Password: '',
      },
      id: '',
      dialogVisible: false,
      dialogVisible1: false,
      tableData: [],
      changeList: [],
      unitList: [],
      roleList: [],
      dataRule: {
        username: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        mobile: [
          {required: true, message: '请输入手机号', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请设置密码', trigger: 'blur'}
        ],
        roleIdList: [
          {required: true, message: '请选择角色', trigger: 'change'}
        ]
      }
    }
  },
  computed: {
    url() {
      return this.$api.host + '/pic/upload?token=' + localStorage.getItem('token')
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex', '2')
    this.$api.post('role/select',  //角色列表
        {}, res => {
          console.log(res, '123')
          this.roleList = res.data
        });
  },
  methods: {
    hideDialog() {
      this.dataForm.mobile =''
      this.dataForm.password =''
      this.dataForm.roleIdList =''
      this.dataForm.username =''
      this.imageUrl =''
      this.dialogVisible = true
      this.password_show = true
      this.titleType = '添加'
    },
    changeTypeClass(row) {
      return row.isExpend == '0' ? "非消耗品" : row.isExpend == '1' ? "消耗品" : "";
    },
    getDataList() {
      this.$api.post('user/getList', {
        "page": this.pageIndex,
        "size": this.pageSize,
      }, res => {
        console.log(res, '123')
        this.tableData = res.data.records
        this.totalPage = res.data.total
      });
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    handleEdit(index, row) {  // 修改按钮
      this.changeList = []
      console.log(index, row.userId);
      if (this.$getPermission('/pc/user/update')) {
        this.dialogVisible = true
        this.password_show = false
        this.titleType = '修改'
        let idx = this.tableData.findIndex((item) => item.userId === row.userId)
        console.log(idx, 'idx')
        this.changeList = this.tableData[idx]
        this.dataForm.username = this.tableData[idx].username
        this.dataForm.mobile = this.tableData[idx].mobile
        this.dataForm.roleIdList = this.tableData[idx].roleIdList
        this.imageUrl = this.tableData[idx].img
        this.id = this.tableData[idx].userId
        console.log(this.changeList, 'this.changeList')
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }

    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      if (this.$getPermission('/pc/user/delById')) {
        this.$api.post('user/delById', {id: row.userId}, res => {
          console.log(res, '删除', this.tableData)
          let idx = this.tableData.findIndex((item) => item.userId === row.userId)
          console.log(idx)
          this.tableData.splice(idx, 1)
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
        })
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }

    },
    addtabList(data) {  //子组件静默添加列表数据
      console.log(data)
      this.tableData.push(data)
      this.dialogVisible = false
    },
    changePassword(index, row) {  //修改密码
      console.log(index, row)
      this.PasswordForm['userId'] = row.userId
      this.dialogVisible1 = true
    },
    // 添加或修改
    update() {
      // this.$refs['dataForm'].validate((valid) => {
      //   if (valid) {
          if (this.id !== '') {  //修改
            this.dataForm['userId'] = this.id
            this.dataForm['img'] = this.imageUrl
            delete this.dataForm.password
            this.$api.post('user/update', this.dataForm, res => {
              console.log(res, 'wwww')
              if (res.code === 0) {
                console.log('修改')
                this.reload()
              }
            })
          } else {   //添加
            this.dataForm['img'] = this.imageUrl
            this.$api.post('user/save', this.dataForm, res => {
              console.log(res, 'wwww')
              if (res.code === 0) {
                console.log('添加成功')
                this.reload()
              }
            })
          }
      //   }
      // })
    },
    // 添加或修改
    updatePassword() {
      //添加
      this.$api.post('user/updatePass', this.PasswordForm, res => {
        console.log(res, 'wwww')
        if (res.code === 0) {
          console.log('添加成功')
          this.reload()
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = file.response;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }
}
</script>


<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}

.table-title-name {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  margin: 20px 0 5px 36px;

}

.dialog-title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 30px;
}

.dialog-button {
  height: 60px;
  background: #009400;
  border-radius: 30px;
  width: 400px;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FEFEFE;
  margin-top: 30px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
