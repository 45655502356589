<template>
  <div>
    <div class="head-name-title" style="margin-left: 20px">角色权限管理</div>
    <div style="margin:20px 0 30px 20px ">
      <el-button type="primary" @click="hideDialog"
                 v-if="this.$getPermission('/pc/role/save')">添加角色
      </el-button>

      <el-dialog
          title=""
          :visible.sync="dialogVisible"
          width="600px"
      >
        <addrole :roleList="roleList" :changeList="changeList"></addrole>
      </el-dialog>
    </div>
    <div>
      <el-table
          :data="tableData"
          border
          style="width: 918px;margin-left: 36px;margin-bottom: 30px"
          :row-class-name="tableRowClassName">
        <el-table-column
            prop="roleId"
            label="编号"
        >
        </el-table-column>
        <el-table-column
            prop="roleName"
            label="角色名称"
        >
        </el-table-column>
        <el-table-column
            prop="menuNames"
            label="权限">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)">编辑
            </el-button>
            <el-popconfirm style="margin-left: 10px"
                           title="确定删除吗？"
                           @confirm="handleDelete(scope.$index, scope.row)"
            >
              <el-button
                  size="mini"
                  type="danger"
                  slot="reference"
              >删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-bottom: 20px;margin-left: 30px"
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import addrole from '@/components/personnel/addrole'
// import {treeDataTranslate} from "@/util";

export default {
  inject: [
    'reload'
  ],
  components: {
    addrole
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataForm: {
        name: '',
        typeId: '',
        unit: '',
        isExpend: '',
      },
      id: '',
      dialogVisible: false,
      tableData: [],
      changeList: {
        bId: '',
        createTime: "",
        createUserId: '',
        menuIdList: [],
        menuNames: "",
        remark: "",
        roleId: '',
        roleName: "",
      },
      unitList: [],
      roleList: []
    }
  },
  mounted() {
    this.getDataList()
    localStorage.setItem('selectIndex', '1')
    this.$api.post('role/select2',  //权限下拉
        {}, res => {
          console.log(res, '12大点的3')
          // this.roleList = treeDataTranslate(res.data, 'menuId')
          this.roleList = res.data
        })
  },
  methods: {
    hideDialog() {
      this.dialogVisible = true
      this.changeList = {}
    },
    changeTypeClass(row) {
      return row.isExpend == '0' ? "非消耗品" : row.isExpend == '1' ? "消耗品" : "";
    },
    getDataList() {
      this.$api.post('role/getList', {
        "page": this.pageIndex,
        "size": this.pageSize,
      }, res => {
        console.log(res, '123')
        this.tableData = res.data.records
        this.totalPage = res.data.total
      });
    },
    tableRowClassName({rowIndex}) {
      if (rowIndex % 2 == 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },

    handleEdit(index, row) {  // 修改按钮
      console.log(index, row.roleId, '查看修改id');
      if (this.$getPermission('/pc/role/update')) {
        this.dialogVisible = true
        this.$api.post('role/getById', {
          "id": row.roleId,
        }, res => {
          console.log(res, '123')
          this.changeList = res.data
        })
        // this.changeList[idx]['roleList'] =  treeDataTranslate(this.changeList[idx]['roleList'], 'menuId')
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }
      console.log(this.changeList, 'this.changeList')
    },
    handleDelete(index, row) {  //删除按钮
      console.log(index, row);
      if (this.$getPermission('/pc/role/delById')) {
        this.$api.post('role/delById', {id: row.roleId}, res => {
          console.log(res, '删除', this.tableData)
          let idx = this.tableData.findIndex((item) => item.roleId === row.roleId)
          console.log(idx)
          this.tableData.splice(idx, 1)
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
        })
      } else {
        this.$message({
          showClose: true,
          message: '暂无权限',
          type: 'warning'
        });
      }

    },
    addtabList(data) {  //子组件静默添加列表数据
      console.log(data)
      this.tableData.push(data)
      this.dialogVisible = false
    },

  }
}
</script>

<style scoped>
.head-name-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  height: 57px;
  line-height: 57px;
  border-bottom: 2px solid #E8E8E8;
}


</style>
